form div {
  grid-auto-flow: column;
  display: grid;
  grid-template-columns: auto auto;
  justify-items: start;
  justify-content: start;
  align-items: start;
  margin: 10px 0;
  grid-template-columns: auto auto;
}
input[type="text"] {
  width: 10vw;
  text-align: right;
}
label {
  padding: 6px;
  color: #aaa;
  width: 80px;
}
input,
button {
  background-color: black;
  color: #aaa;
  border: 2px solid #aaa;
  border-radius: 10px;
  padding: 5px;
  font-family: "Fredericka the Great";
}
.uripreview {
    height: 240px;
    border: 1px solid #aaa;
}
.uripreview img {
  display: block;
  margin: 0 auto;
  max-width: 240px;
  max-height: 240px;
}
div.nav {
  width: 100%;
  justify-content: center;
  align-items: center;
}
button.nav {
  padding: 5px 20px;
}
a.nav {
  width: 50px;
  text-align: center;
  padding: 5px 20px;
  font-size: 2em;
  font-weight: bold;
  cursor: pointer;
}
.Collection button {
    border: 2px white solid;
    border-radius: 10px;
    padding: 5px 10px;
    margin: 2px 5px;
    background-color: #000;
    font-size: calc(2px + 2vmin);
    color: #aaa;
    cursor: pointer;
    font-family: "Megrim";
}
  
.Collection input {
    border: 2px white solid;
    border-radius: 10px;
    padding: 5px 10px;
    margin: 2px 5px;
    background-color: #000;
    font-size: calc(2px + 2vmin);
    color: #aaa;
    font-family: "Megrim";
}

.Collection input[type=checkbox] {
    margin: 13px auto 0 3px;
}
