@font-face {
  font-family: "Megrim";
  src: url(/public/Megrim-Regular.ttf) format("truetype");
}

body {
  background-color: #000;
  background-image: url(/public/background.jpg);
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
  font-size: calc(8px + 2vmin);
  font-family: "Megrim";
  margin: 0;
}

nav {
  position: absolute;
}

nav img {
  width: 5%;
  margin-right: 1%;
}

.App {
  text-align: center;
}

.App-header {
  margin-left: 20%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #aaa; 
}

a {
  text-decoration: none;
  color: #aaa;
}

a:hover {
  color: #fff;
}

.ConnBtnDiv-connected {
  position: absolute;
  top: 10px;
}

button.NetworkSwitch {
  margin-top: 20px;
  border: 2px white solid;
  border-radius: 10px;
  padding: 10px 20px;
  background-color: #000;
  font-size: calc(5px + 2vmin);
  color: #aaa;
  cursor: pointer;
  font-family: "Megrim";
}

.QuatreCentQuatre {
  font-size: 25vw;
}
