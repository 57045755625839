.Admin-title {
    color: #fff;
    font-size: calc(12px + 2vmin);
}

.Admin-StepChooser-Title {
    color: #fff;
    margin-bottom: 10px;
}

.Admin-StepChooser img.loading {
    width: 48px;
    height: 48px;
}

.Admin-StepChooser {
    margin-top: 20px;
}


.Admin-StepChooser button {
    border: 2px white solid;
    border-radius: 10px;
    padding: 5px 10px;
    margin: 2px 5px;
    background-color: #000;
    font-size: calc(2px + 2vmin);
    color: #aaa;
    cursor: pointer;
    font-family: "Megrim";
}
  
.Admin-StepChooser button.activestep{
    color: #fff;
    font-weight: bold;
}

.Admin-StepChooser input {
    border: 2px white solid;
    border-radius: 10px;
    padding: 5px 10px;
    margin: 2px 5px;
    background-color: #000;
    font-size: calc(2px + 2vmin);
    color: #aaa;
    font-family: "Megrim";
}

.Dashboard-admin {
    margin: 20px;
}



