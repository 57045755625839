.mintpriceinfos {
  padding: 20px;
}

.projectstepinfo {
  margin: 20px;
  color: #fff;
  font-size: calc(12px + 2vmin);  
}

.actionbuttons {
  margin-top: 20px;
}

.actionbuttons button {
  border: 2px white solid;
  border-radius: 10px;
  padding: 10px 20px;
  background-color: #000;
  font-size: calc(5px + 2vmin);
  color: #aaa;
  cursor: pointer;
  font-family: "Megrim";
}

.Dashboard-title,
.Admin-title {
  color: #fff;
  font-size: calc(12px + 2vmin);
}

img.loading {
  width: 48px;
  height: 48px;
}

.BFRpreview img {
  margin: 20px;
  width: 30%;
}